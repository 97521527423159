<template>
  <b-modal
      id="gsb-upload-image-modal"
      hide-footer
      size="md"
      title="อัพโหลดรูปภาพใบหน้า Mymo by GSB"
  >
    <b-overlay :show="isFetching">
      <b-img v-if="sampleImage" :src="sampleImage" class="mx-auto mb-3" fluid />
      <b-img v-else-if="gsbAccount.faceImageUrl" :src="gsbAccount.faceImageUrl" class="mx-auto mb-3" fluid />
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('bank_accounts.account_name')"
            rules="required"
          >
            <b-form-group
              :invalid-feedback="errors[0]"
              label="รูปหน้า"
              label-cols-lg="2"
              label-for="form-image"
            >
              <b-input-group>
                <b-form-file
                  v-model="image"
                  :state="errors[0] ? false : null"
                  accept="image/*"
                ></b-form-file>
              </b-input-group>
              <template #description>
                <div v-if="image" class="text-right">
                  <a class="text-danger" href="#" @click.prevent="image = null">(ลบ)</a>
                </div>
              </template>
            </b-form-group>
          </ValidationProvider>

          <div class="text-right">
            <b-button :disabled="isFetching || !image" type="submit" variant="success">
              {{ $t('buttons.submit') }}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'GsbUploadImageModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    gsbAccount: {
      type: Object,
      required: true,
      default: () => ({
        faceImageUrl: '',
      })
    }
  },
  data() {
    return {
      image: null,
      isFetching: false,
    }
  },
  computed: {
    sampleImage() {
      return this.image ? URL.createObjectURL(this.image) : null
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (_, id) => {
      if (id === 'gsb-upload-image-modal') {
        this.form = {
          idCard: this.gsbAccount?.idCard || '',
          phoneNumber: this.gsbAccount?.phoneNumber || '',
          pin: this.gsbAccount?.pin || '',
        }
        this.step = 1
      }
    })
  },
  methods: {
    ...mapActions(['uploadGSBImage']),
    async onSubmit() {
      if (!this.image) return;
      this.isFetching = true
      const response = await this.uploadGSBImage({ id: this.agentBankAccountId, image: this.image })
      if (response) {
        this.$bvModal.hide('gsb-upload-image-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
  }
})
</script>

<style scoped>

</style>